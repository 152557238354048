import React, { useRef, useEffect } from 'react';
import PopupWithForm from './PopupWithForm';
import useFormWithValidation from '../hooks/useFormWithValidation';

const EditAvatarPopup = ({ onUpdateAvatar, onOverlayClick, isLoading, isOpen, onClose }) => {
  const avatarRef = useRef();
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation();

  useEffect(() => {
    resetForm();
    avatarRef.current.focus();
  }, [resetForm, isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateAvatar({
      avatar: values.avatar,
    });
  }

  return (
    <PopupWithForm
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onOverlayClick={onOverlayClick}
      isLoading={isLoading}
      isDisabled={!isValid || isLoading}
      name="edit-avatar"
      title="Обновить аватар"
    >
      <label htmlFor="avatar" className="popup__label">
        <input
          type="url"
          name="avatar"
          id="avatar"
          className={`popup__input${!isValid && errors.avatar ? ' popup__input_type_error' : ''}`}
          placeholder="Ссылка на картинку"
          value={values.avatar || ''}
          onChange={handleChange}
          required
          ref={avatarRef}
        />
        <p
          className={`popup__error${!isValid ? ' popup__error_visible' : ''}`}
          id="avatar-error"
        >
          {errors.avatar || ''}
        </p>
      </label>
    </PopupWithForm>
  )
}

export default EditAvatarPopup;
